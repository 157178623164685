<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຂາຍ</h2></v-col>
      <v-col class="al-end">
        <v-btn
            color="btn-primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export
        </v-btn
        >
      </v-col
      >
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col>
        <v-menu
            v-model="start_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
            v-model="end_menu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="end_date"
                label="ຫາວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="end_date"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-autocomplete
            v-model="selectedPayment"
            :items="payment_Types"
            label="ປະເພດຊຳລະ"
            item-text="name"
            item-value="value"
            dense
            outlined
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex">
        <v-select
            v-model="selectedSaleType"
            :items="sale_Types"
            label="ປະເພດການການຂາຍ"
            item-text="name"
            item-value="value"
            dense
            outlined
        ></v-select>
      </v-col>

      <v-col class="d-flex" v-if="selectedSaleType !='all'">
        <v-autocomplete
            v-model="selectedSaleTypeId"
            :items="dataSource"
            label="ຕົວເລືອການການຂາຍ"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-autocomplete>
      </v-col>

      <v-col class="d-flex">
        <v-autocomplete
            v-model="selectedUser"
            :items="users"
            label="User"
            item-text="name"
            item-value="id"
            dense
            outlined
        ></v-autocomplete>
      </v-col>

      <v-col>
        <v-text-field
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-account-search-outline"
            label="ເລກບິນ,ຊື່ລູກຄ້າ,ເບີໂທ"
            type="text"
            v-model="search"
            @keyup.enter="Search()"
        >
        </v-text-field>
      </v-col>
      <v-col class="al-end">
        <v-btn
            color="primary"
            @click="fetchData()"
        >Filter
        </v-btn
        >
      </v-col
      >
    </v-row>
    <v-row class="mb-1 mt-0">
      <v-col>
        <h4>ລວມບິນ: {{ Intl.NumberFormat().format(totalBill) }}</h4>
      </v-col>
      <v-col>
        <h4>ລວມຕຸກນ້ຳ: {{ Intl.NumberFormat().format(sumTotalBottles) }}</h4>
      </v-col>

      <v-col>
        <h4>ລວມເງິນທັງໝົດ: {{ Intl.NumberFormat().format(sumTotalPrice) }}</h4>
      </v-col>
      <v-col>
        <h4>
          ລວມເງິນທີ່ຂາຍນ້ຳ: {{ Intl.NumberFormat().format(sumTotalPriceOfBottles) }}
        </h4>
      </v-col>

    </v-row>
    <v-divider></v-divider>
    <v-row class="mb-1 mt-1">
      <v-col>
        <h4 class="primary--text">
          ເງິນສົດ: {{ Intl.NumberFormat().format(sumTotalCash) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="success--text">
          ທະນາຄານ: {{ Intl.NumberFormat().format(sumTotalBank) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="info--text">
          ສະໜັບສະໜຸນ: {{ Intl.NumberFormat().format(sumTotalSupport) }}
        </h4>
      </v-col>
      <v-col>
        <h4 class="error--text">
          ຕິດໜີ້: {{ Intl.NumberFormat().format(sumTotalDebt) }}
        </h4>
      </v-col>
      <v-col>
        <h4>ລວມສ່ວນຫຼຸດ: {{ Intl.NumberFormat().format(sumTotalDiscount) }}</h4>
      </v-col>
      <v-col>
        <h4>ລວມມັດຈຳ: {{ Intl.NumberFormat().format(sumTotalBondingFees) }}</h4>
      </v-col>

      <!--
      <v-col>
        <h4 class="warning--text">
          ຄ່າມັດຈຳ: {{ Intl.NumberFormat().format(totalBondingFee()) }}
        </h4>
      </v-col>
-->
    </v-row>

    <!-- <hr /> -->
    <v-data-table
        :headers="headers"
        :items="invoices"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <template v-slot:item.total="{ item }">
        {{ Intl.NumberFormat().format(item.subTotal + item.bonding_fees - item.discount) }}
      </template>
      <template v-slot:item.bonding_fees="{ item }">
        {{ Intl.NumberFormat().format(item.bonding_fees) }}
      </template>
      <template v-slot:item.discount="{ item }">
        {{ Intl.NumberFormat().format(item.discount) }}
      </template>
      <template v-slot:item.subTotal="{ item }">
        {{ Intl.NumberFormat().format(item.subTotal) }}
      </template>
      <!--
      <template v-slot:item.createBy="{ item }">
        {{ item.createBy.name }}
      </template>
      <template v-slot:item.customer="{ item }">
        {{ item.customer.name }}
      </template>
      -->
      <template v-slot:item.team="{ item }">
        <div v-if="item.created_by_type">
          {{ item.created_by_type.name }}
        </div>
        <div>
          <span v-if="item.createBy.name" class="primary-color">{{
              item.createBy.name
            }}</span>
          <span v-for="(member, index) in item.createByMembers" :key="index">
            <span>, {{ member.name }} </span>
          </span>
        </div>
      </template>

      <template v-slot:item.paymentDate="{ item }">
        <div v-if="item.paymentDate">
          {{ item.paymentDate }}
        </div>
        <div v-else>
          <v-chip color="error">ຍັງບໍທັນຊຳລະ</v-chip>
        </div>
      </template>
      <template v-slot:item.invoice_status="{ item }">
        <v-chip color="success">{{ item.invoice_status }}</v-chip>
      </template>
      <template v-slot:item.payment_type="{ item }">
        <div :class="getPaymentTypeColor(item.payment_type)">
          {{ paymentType(item.payment_type) }}
        </div>
      </template>

      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editInvoice(item.id)"
        >mdi-pen
        </v-icon
        >
        <!--        <v-icon small class="mr-2" @click="viewInvoice(item.id)"-->
        <!--        >mdi-eye-->
        <!--        </v-icon-->
        <!--        >-->
        <v-icon small @click="deleteItem(item.id)"> mdi-delete</v-icon>
      </template>
    </v-data-table>
    <br/>
    <template>
      <Pagination
          v-if="pagination.last_page > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchData()"
      ></Pagination>
    </template>


    <ModalView v-if="invoiceDetail">
      <template @close="close">
        <v-card mdi-map-marker-circle :loading="loading" :disabled="loading">
          <v-card-title>
            <h4 class="align-center">ລາຍລະອຽດໃບບິນ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <div class="invoice" v-if="invoiceDetail.factory"> {{ invoiceDetail.factory.name }}
                    {{ invoiceDetail.factory.phone }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ເລກບິນ: {{ invoiceDetail.invoice_number }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="invoice">
                    ວັນທີຂາຍ: {{ invoiceDetail.createdDate }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ປະເພດການຊຳລະ:
                    <span
                        :class="getPaymentTypeColor(invoiceDetail.payment_type)"
                    >{{ paymentType(invoiceDetail.payment_type) }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="invoice">
                    ປະເພດຜູ້ຂາຍ:
                    <span class="primary-color">{{
                        saleByType(invoiceDetail.create_by_type)
                      }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" v-if="createdByDetail.name">
                  <div class="invoice">
                    ຜູ້ຂາຍ:
                    <span class="primary-color">{{
                        createdByDetail.name
                      }}</span>
                  </div>
                  <span
                      v-for="(item, index) in invoiceDetail.createByMembers"
                      :key="index"
                  >
                    {{ item.name }}
                  </span>
                </v-col>
                <v-col cols="6" v-if="customerDetail.name">
                  <div class="invoice">
                    ຂາຍໃຫ້: {{ customerDetail.name }}
                    {{ customerDetail.surname }}
                    {{ customerDetail.village_name }}, ໂທ:
                    {{ customerDetail.phone }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ສະຖານະ
                    <v-chip color="success">{{
                        invoiceDetail.invoice_status
                      }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
              <v-data-table
                  :headers="invoiceHeader"
                  :items="invoiceDetailItem"
                  :item-per-page="15"
                  :disable-pagination="true"
              >
                <template v-slot:item.price="{ item }">
                  <div>
                    {{ item.price }}
                  </div>
                </template>
                <template slot="item.index" scope="props">
                  <div>{{ props.index + 1 }}</div>
                </template>
                <!--                <template v-slot:item.action="{ item }">-->
                <!--                  <v-icon small @click="deleteItem(item.productId)"> mdi-delete </v-icon>-->
                <!--                </template>-->
              </v-data-table>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h2 class="invoice justify-end">ຈຳນວນຕຸກນ້ຳ:</h2>
                </v-col>
                <v-col class="d-flex justify-end">
                  <h2 cols="3" class="invoice justify-end">
                    {{ invoiceDetail.totalBottle }}
                  </h2>
                </v-col>
              </v-row>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h2 class="invoice justify-end">ລວມຄ່ານ້ຳທັງໝົດ:</h2>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <h2 class="invoice justify-end">
                    {{ Number(invoiceDetail.subTotal).toLocaleString() }} LAK
                  </h2>
                </v-col>
              </v-row>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h2 class="invoice justify-end">ລວມຄ່າມັດຈຳ:</h2>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <h2 class="invoice justify-end">
                    {{ Number(invoiceDetail.bonding_fees).toLocaleString() }}
                    LAK
                  </h2>
                </v-col>
              </v-row>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h2 class="invoice justify-end primary-color">ສ່ວນຫຼຸດ:</h2>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <h2 class="invoice justify-end primary-color">
                    {{ Number(invoiceDetail.discount).toLocaleString() }}
                    LAK
                  </h2>
                </v-col>
              </v-row>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h3>ທັງໝົດທີ່ຕ້ອງຈ່າຍ:</h3>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <h3>
                    {{
                      totalPay(
                          invoiceDetail.subTotal,
                          invoiceDetail.bonding_fees, invoiceDetail.discount
                      )
                    }}
                    LAK
                  </h3>
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-col>
                  <v-menu
                      v-model="payment_menu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="payment_date"
                          label="ເລີ່ມວັນທີ"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="payment_date"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <br/>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" class="mr-2" depressed @click="closeModal()">
                Close
              </v-btn>
              <v-btn
                  color="primary"
                  depressed
                  @click="confirmPaymentStatus('cash')"
              >
                ຊຳລະເງິນສົດ
              </v-btn>
              <v-btn
                  color="success"
                  class="mr-2"
                  depressed
                  @click="confirmPaymentStatus('bank')"
              >
                ຊຳລະທະນາຄານ
              </v-btn>
              <v-btn
                  color="info"
                  class="mr-2"
                  depressed
                  @click="confirmPaymentStatus('support')"
              >
                ສະໝັບສະໜູນ
              </v-btn>
              <v-btn
                  color="warning"
                  class="mr-2"
                  depressed
                  @click="confirmPaymentStatus('debt')"
              >
                ຕິດໜີ້
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>

    <!--    Delete Modal-->

    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span>ລຶບໃບບິນ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        label="ເລືອກຜະລິດຕະພັນ *"
                        required
                        v-model="productType"
                        :items="productTypes"
                        item-text="name"
                        item-value="id"
                        :rules="productTypeRules"
                    ></v-select>
                    <p class="errors">
                      {{ server_errors.productType }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ຈຳນວນຜະລິດຕະພັນ *"
                        required
                        v-model="amount"
                        :rules="amountRules"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.amount }}
                    </p>
                  </v-col>
                  <p class="errors" v-if="msg_errors">
                    {{ msg_errors }}
                  </p>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete()">
                ຍົກເລີກ
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="deleteItemConfirm()"
              >
                ຢືນຢັນ
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import {GetOldValueOnInput} from "../../../Helpers/GetValue";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      payment_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      start_menu: false,
      end_menu: false,
      payment_menu: false,

      loading: false,
      exportLoading: false,
      TableLoading: false,
      invoiceId: "",
      invoices: [],
      invoiceDetailItem: [],
      users: [],
      selectedUser: "all",
      selectedSaleTypeId: "all",
      dataSource: [],
      invoiceDetail: {},
      customerDetail: {},
      createdByDetail: {},
      //Sum Variable
      totalBill: 0,
      sumTotalPrice: 0,
      sumTotalCash: 0,
      sumTotalDebt: 0,
      sumTotalBank: 0,
      sumTotalSupport: 0,
      sumTotalBottles: 0,
      sumTotalBondingFees: 0,
      sumTotalBondingFeeByBank: 0,
      sumTotalBondingFeeByCash: 0,
      sumTotalBondingFeeByDebt: 0,
      sumTotalBondingFeeBySupport: 0,
      sumTotalDiscount: 0,
      sumTotalPriceOfBottles: 0,// ລວມເງິນຂາຍນ້ຳ
      deleteId: "",
      //Payment Type

      selectedSaleType: "all",
      sale_Types: [
        {
          name: "ທັງໝົດ",
          value: "all",
        },
        {
          name: "ຍອດຂາຍຕາມສາຂາ",
          value: "branch",
        },
        {
          name: "ຍອດຂາຍຕາມສາງໂຮງງານ",
          value: "warehouse",
        },
        {
          name: "ຍອດຂາຍຕາມລົດຂົນສົ່ງ",
          value: "driver",
        }
      ],
      selectedPayment: "all",
      defaultValue: {
        id: "all",
        name: "ທັງໝົດ",
      },
      payment_Types: [
        {
          value: "all",
          name: "ທັງໝົດ",
        },
        {
          value: "cash",
          name: "ເງິນສົດ",
        },
        {
          value: "bank",
          name: "ທະນາຄານ",
        },
        {
          value: "support",
          name: "ສະໜັບສະໜູນ",
        },
        {
          value: "debt",
          name: "ຕິດໜີ້",
        },
      ],
      //Pagination
      offset: 12,
      pagination: {},
      per_page: 25,
      search: "",
      oldVal: "",
      headers: [
        {text: "", value: "actions", sortable: false, align: "center", width: "80"},
        {
          text: "ເລກບິນ",
          align: "start",
          sortable: false,
          value: "invoice_number",
          width: "100"
        },
        {
          text: "ລະຫັດລູກຄ້າ",
          align: "start",
          sortable: false,
          value: "customer.customer_code",
          width: "150"
        },
        // { text: "ຜູ້ຂາຍ", value: "createBy.name" },
        {text: "ລູກຄ້າ", value: "customer.name", sortable: false, width: "150"},
        {text: "ຈຳນວນ", align: "center", value: "totalBottle", width: "100"},
        {text: "ມັດຈຳ", value: "bonding_fees", width: "100"},
        {text: "ສ່ວນຫຼຸດ", value: "discount", width: "120"},
        {text: "ຄ່ານ້ຳ", value: "subTotal", width: "100"},
        {text: "ລວມເງິນ", value: "total", width: "100"},
        {text: "ວັນທີຂາຍ", value: "createdDate", width: "150"},
        {text: "ປະເພດຊຳລະ", value: "payment_type", width: "150"},
        {text: "ວັນທີຊຳລະ", value: "paymentDate", width: "150"},
        {text: "ທີມຂາຍ", value: "team", align: "start", sortable: false, width: "250"},
        // { text: "ສະຖານະ", value: "invoice_status", align: "center" },
      ],
      editedIndex: -1,
      productTypeRules: [
        v => !!v || 'Product Type is required',
      ],
      amountRules: [
        v => !!v || 'Amount is required',
      ],
      productType: "",
      amount: "",
      productTypes: [],
      server_errors: {},
      msg_errors: "",


      invoiceHeader: [
        {
          text: "ລະຫັດ",
          align: "center",
          sortable: false,
          value: "barcode",
        },
        {text: "ລາຍການ", value: "name"},
        {text: "ລາຄາ", value: "price"},
        {text: "ຈຳນວນ", value: "qty"},
        {text: "ລວມ", value: "total"},
        {text: "", value: "action"},
      ],
      toast: {
        value: true,
        color: 'success',
        msg: 'Success'
      },
      toast_error: {
        value: true,
        color: 'error',
        msg: 'Something when wrong!'
      }
    };
  },
  metaInfo() {
    return {
      title: `ສະຫຼຸບຍອດຂາຍ - ທັງໝົດ`,
      meta: [
        {name: 'description', content: 'ສະຫຼຸບຍອດຂາຍ - ທັງໝົດ'},
      ]
    }
  },
  methods: {
    getFactory() {
      this.$admin.get('list-factories').then(res => {
        this.factories = res.data.data.data;
        this.TableLoading = false;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    getProductType() {
      this.$axios.get('list-bottle-types').then(res => {
        this.productTypes = res.data.bottle_types;
      }).catch(() => {
        this.$store.commit("Toast_State", this.toast_error);
      });
    },

    fetchData() {
      // var rolesUser = ["drivermanager", "driveruser", "driverandbranch"];
      this.TableLoading = true;
      this.$axios
          .post("finance/report-invoices/", {
            startDate: this.start_date,
            endDate: this.end_date,
            payment_type: this.selectedPayment,
            create_by_type: this.selectedSaleType,
            created_by_type_id: this.selectedSaleTypeId,
            created_by: this.selectedUser,
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.search,

          })
          .then((res) => {
            setTimeout(() => {
              this.invoices = res.data.invoices.data;
              this.pagination = res.data.invoices;
              this.totalBill = res.data.totalBill;
              this.sumTotalPrice = res.data.sumTotalPrice;
              // this.sumTotalDebt = res.data.sumTotalDebt;
              // this.sumTotalBank = res.data.sumTotalBank;
              // this.sumTotalSupport = res.data.sumTotalSupport;
              this.sumTotalBottles = res.data.sumTotalBottles;
              this.sumTotalPriceOfBottles = res.data.sumTotalPriceOfBottles;
              // this.sumTotalBondingFees = res.data.SumTotalBondingFees;
              // this.sumTotalBondingFeeByBank = res.data.sumTotalBondingFeeByBank;
              // this.sumTotalBondingFeeByCash = res.data.sumTotalBondingFeeByCash;
              // this.sumTotalBondingFeeByDebt = res.data.sumTotalBondingFeeByDebt;
              // this.sumTotalBondingFeeBySupport =
              //   res.data.sumTotalBondingFeeBySupport;
              // this.sumTotalDiscountByBank = res.data.sumTotalDiscountByBank;
              // this.sumTotalDiscountByCash = res.data.sumTotalDiscountByCash;
              // this.sumTotalDiscountByDebt = res.data.sumTotalDiscountByDebt;
              // this.sumTotalDiscountBySupport = res.data.sumTotalDiscountBySupport;

              if (this.selectedPayment == "all") {
                this.sumTotalCash =
                    res.data.sumTotalCash +
                    res.data.sumTotalBondingFeeByCash -
                    res.data.sumTotalDiscountByCash;
                this.sumTotalBank =
                    res.data.sumTotalBank +
                    res.data.sumTotalBondingFeeByBank -
                    res.data.sumTotalDiscountByBank;
                this.sumTotalDebt =
                    res.data.sumTotalDebt +
                    res.data.sumTotalBondingFeeByDebt -
                    res.data.sumTotalDiscountByDebt;
                this.sumTotalSupport =
                    res.data.sumTotalSupport +
                    res.data.sumTotalBondingFeeBySupport -
                    res.data.sumTotalDiscountBySupport;
                this.sumTotalBondingFees = res.data.SumTotalBondingFees;
                this.sumTotalDiscount = res.data.sumTotalDiscount;
              } else if (this.selectedPayment == "cash") {
                this.sumTotalCash = res.data.sumTotalCash;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeByCash;
                this.sumTotalDiscount = res.data.sumTotalDiscountByCash;
                this.sumTotalBank = "";
                this.sumTotalDebt = "";
                this.sumTotalSupport = "";
              } else if (this.selectedPayment == "bank") {
                this.sumTotalBank = res.data.sumTotalBank;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeByBank;
                this.sumTotalDiscount = res.data.sumTotalDiscountByBank;
                this.sumTotalCash = "";
                this.sumTotalDebt = "";
                this.sumTotalSupport = "";
              } else if (this.selectedPayment == "debt") {
                this.sumTotalDebt = res.data.sumTotalDebt;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeByDebt;
                this.sumTotalDiscount = res.data.sumTotalDiscountByDebt;
                this.sumTotalBank = "";
                this.sumTotalCash = "";
                this.sumTotalSupport = "";
              } else if (this.selectedPayment == "support") {
                this.sumTotalSupport = res.data.sumTotalSupport;
                this.sumTotalBondingFees = res.data.sumTotalBondingFeeBySupport;
                this.sumTotalDiscount = res.data.sumTotalDiscountBySupport;
                this.sumTotalBank = "";
                this.sumTotalCash = "";
                this.sumTotalDebt = "";
              }
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    //View Invoice
    viewInvoice(value) {
      let route = this.$router.resolve({name: 'InvoiceDetail', params: {id: value}});
      window.open(route.href, '_blank');
    },
    editInvoice(value) {
      this.loading = true;
      this.invoiceId = value;
      this.$store.commit("modalView_State", true);
      this.$axios
          .get("finance/report-invoice-detail/" + this.invoiceId)
          .then((res) => {
            setTimeout(() => {
              this.invoiceDetail = res.data.invoice;
              this.customerDetail = res.data.invoice.customer;
              this.createdByDetail = res.data.invoice.createBy;
              this.invoiceDetailItem = res.data.invoiceDetails;
              this.loading = false;
            }, 100);
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },

    listUser() {
      this.$axios
          .get("list/driver/and/branch/users")
          .then((res) => {
            setTimeout(() => {
              this.users = res.data.users;
              this.users.unshift(this.defaultValue);
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    listSaleDataSources() {
      if (this.selectedSaleType == 'branch') {
        this.$admin.get('list-branch').then(res => {
          this.dataSource = res.data.branches;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      } else if (this.selectedSaleType == 'driver') {
        let originalData = [];
        this.$axios
            .get("list-water-truck")
            .then((res) => {
              originalData = res.data.trucks;
              const newData = originalData.map(item => {
                const newItem = {...item, name: item.license_plate};
                delete newItem.license_plate; // Remove the old key
                return newItem;
              });
              this.dataSource = newData;
              this.dataSource.unshift(this.defaultValue);
            })
            .catch(() => {
              this.$store.commit("Toast_State", this.toast_error);
            });

      } else if (this.selectedSaleType == 'warehouse') {
        this.$admin.get('list-factories').then(res => {
          this.dataSource = res.data.data.data;
          this.dataSource.unshift(this.defaultValue);
        }).catch(() => {
          this.$store.commit("Toast_State", this.toast_error);
        });
      }
    },


    //Confirm Payment type
    confirmPaymentStatus(status) {
      let data = {
        "invoice_id": this.invoiceId,
        "payment_type": status,
        "payment_date": this.payment_date
      }
      this.loading = true;
      this.$axios
          .post("payment/customer/invoice", data)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast);
              }, 100);
            }
            this.closeModal();
            this.fetchData();
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.closeModal();
            if (error.response.status == 422) {
              this.$store.commit("Toast_State", this.toast_error);
            } else {
              this.$store.commit("Toast_State", this.toast_error);
            }
          });
    },

    closeModal() {
      this.$store.commit("modalView_State", false);
    },
    paymentType(value) {
      if (value == "debt") return "ຕິດໜີ້";
      else if (value == "cash") return "ເງິນສົດ";
      else if (value == "bank") return "ທະນາຄານ";
      else if (value == "support") return "ສະໜັບສະໝູນ";
      else return;
    },
    getPaymentTypeColor(value) {
      if (value == "bank") return "success--text";
      else if (value == "cash") return "primary--text";
      else if (value == "debt") return "error--text";
      else if (value == "support") return "purple--text";
    },
    saleType(sale, price) {
      if (sale == "sale") return Number(price).toLocaleString();
      else if (sale == "free") return "Free";
    },
    getFreeStyle(sale) {
      if (sale == "free") return "primary-color";
    },
    totalPay(b, total, discount) {
      return Number(b + total - discount).toLocaleString();
    },
    saleByType(type) {
      if (type == "driver") {
        return "ລົດຂົນສົ່ງ";
      } else if (type == "branch") {
        return "ສາຂາ";
      }
    },
    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
          .post(
              "export/invoices",
              {
                startDate: this.start_date,
                endDate: this.end_date,
                payment_type: this.selectedPayment,

              },
              {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute("download", `Report-sale` + ".xlsx");
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$store.commit("Toast_State", this.toast_error);
            }
            this.exportLoading = false;
          });
    },
    closeDelete() {
      this.amount = "",
          this.$store.commit("modalAdd_State", false);
    },
    deleteItem(id) {
      this.deleteId = id;
      this.getProductType();
      this.$store.commit("modalAdd_State", true);
    },
    deleteItemConfirm() {
      this.loading = true;
      this.$admin.post('cancel-bottle-from-customer/' + this.deleteId, {
        type_id: this.productType,
        amount: this.amount,
      }).then(res => {
        if (res.data.success == true) {
          this.fetchData();
          this.$store.commit("modalAdd_State", false);
          this.loading = false;
          this.deleteId = '';
          this.$store.commit("Toast_State", this.toast);
        }
      }).catch((error) => {
        this.fetchData();

        if (error.response.status == 422) {
          let obj = error.response.data.msg;
          this.msg_errors = error.response.data.msg;
          this.loading = false;
          this.$store.commit("Toast_State", obj);
          for (let [key, addValue] of Object.entries(obj)) {
            this.server_errors[key] = addValue[0];
          }
        }
        this.$store.commit("modalDelete_State", false);
        this.loading = false;
      })
    },
    Search() {
      GetOldValueOnInput(this);
    },
  },
  watch: {
    selectedSaleType: function () {
      this.listSaleDataSources();
    },
    // selectedUser: function () {
    //   this.pagination.current_page = '';
    //   this.fetchData();
    // }, start_date: function () {
    //   this.pagination.current_page = '';
    //   this.fetchData();
    // },
    // end_date: function () {
    //   this.pagination.current_page = '';
    //   this.fetchData();
    // },
    // search: function (value) {
    //   this.pagination.current_page = '';
    //   if (value == "") {
    //     this.fetchData();
    //   }
    // },
  },
  created() {
    this.listUser();
    this.fetchData();
    // this.getFactory();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}

.invoice {
  font-size: 16px;
  color: #000000;
}

.primary-color {
  color: #00c1d2;
}
</style>
